.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.loader-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 100vh;
}
